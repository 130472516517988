import React from 'react'
import { Helmet } from 'react-helmet'

function Widget({ amount,country, crypto, wallet, opId, fiatCurrency }) {
    const ref = React.createRef()
    const [money, setMoney] = ('EUR')
    const chooseMoney = () => {
        if (country === "US" || country === "CA") {
            return "USD"
        }
        else return "EUR"
    } 
    console.log('le moneu', chooseMoney())

    return (
        <>
            <div id="mercuryo-widget"></div>
            <Helmet> 
            <script>
                {`
                    setTimeout(() => {
                        mercuryoWidget.run({
                            widgetId: 'c265d0fa-2797-47f6-9a83-b69003150687',
                            merchantTransactionId: '${opId}',
                            host: document.getElementById('mercuryo-widget'),
                            type: 'buy',
                            width: 350,
                            height: 650,
                            fixAmount: true,
                            currency: 'USDT',
                            fixCurrency: true,
                            fiatAmount: ${amount},
                            fixFiatAmount: true,
                            fiatCurrency: '${fiatCurrency}',
                            fixFiatCurrency: true,
                            address: 'TGfytEhzbaomKaNYUY9SUAGcKy2xG59tEs',
                            signature: '74dea6a7f6ed528357656ac7ea9f48c942ddfcfc23d1d2648161f48e1680e2a8512a5b8eb99f32d746ac6ca5585418ae6e3bec2dfa3683b19bcbe90e1a4117f5',
                            hideAddress: true,
                            ratesFeeOff: true,
                            theme: 'ipc',
                            network: 'TRON'
                        });
                    }, 0);
                `}
            </script>
                {/* <script>{`
                        setTimeout(() => {
                            mercuryoWidget.run({
                                widgetId: 'c265d0fa-2797-47f6-9a83-b69003150687',
                                merchantTransactionId: '${opId}',
                                host: document.getElementById('mercuryo-widget'),
                                type: 'buy',
                                width: 350,
                                height: 650,
                                fixAmount: true,
                                currency: 'USDC',
                                fixCurrency: true,
                                fiatAmount: ${amount},
                                fixFiatAmount: true,
                                fiatCurrency: 'EUR',
                                fixFiatCurrency: true,
                                address: 'FZ6dCcRA7V9L95hpVxSn3ZrDaFAkK5Juit7Y3bBTK3Tx',
                                signature: '6b7ba87cc1911749924d31b7acbb9d2a98d9fe730e1ec488030b85b467ed1095f8e4c41b0461cb6b2ebcb62eabaa6586f15d9bf8986d4d84580603c7b692dc67',
                                hideAddress: true,
                                ratesFeeOff: true,
                                theme: 'ipc',
                                network: 'SOLANA'
                            })
                        }, 0);
                    `}</script> */}
            </Helmet>
        </>

    )
}

export default Widget

