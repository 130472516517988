import React, { useState } from 'react'
import i18n from 'i18next'

import './lang.css'

let language=JSON.parse(localStorage.getItem('lang')) || {lang: 'fr', flag: 1}
let leng = language.lang === "fr" ? "fr" : "gb"
function Lang() {
    const [state, setState]=useState({lang: language.lang, flag: language.flag})
    const [iso, setIso] = useState({iso: leng})
    const myRef=React.createRef(null)
    const changeLanguage=(lang, flag, iso)=>{
        i18n.changeLanguage(lang)
        setState({lang: lang, flag: flag})
        setIso({iso: iso})
        // console.log(lang,flag)
        localStorage.setItem('lang', JSON.stringify({lang: lang, flag: flag}))
        // console.log(localStorage.getItem('lang'),localStorage.getItem('flag'))
    }
    const click=(target)=>{
        myRef.current.classList.toggle("show")
    }
    const blur=()=>{
        myRef.current.classList.remove('show')
    }
//    console.log(language)
    return (
        <div className="lang" onClick={(e)=>click(e.target)} onBlur={blur} tabIndex="0">
            <div className="active-lang">
                <div className="lang-item">
                <span class={`fi fi-${iso.iso}`}></span> 
                    <span>{state.lang}</span>
                </div>
                
            </div>
            <div className="langs" ref={myRef}>
                <div className="lang-item" onClick={()=>changeLanguage("en", 0, "gb")}> <span class="fi fi-gb"></span><span>en</span></div>
                <div className="lang-item" onClick={()=>changeLanguage("fr", 1, "fr")}><span class="fi fi-fr"></span> <span>fr</span></div>
            </div>
        </div>
    )
}

export default Lang
